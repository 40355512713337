import router from "@/router";

export function setMenu(){
  const powers = JSON.parse(sessionStorage.getItem('powers'));
  const routes = Permission(powers || []);
  router.options.routes[0].children = routes;

  router.options.routes[0].children.push(...[
    //数据看板
    {
      path: '/board/index',
      name: '数据看板',
      hidden: false,
      component: () => import('@/views/board/index')
    },
    //成果编辑
    {
      path: '/admin/chengguo/edit',
      name: '成果',
      hidden: true,
      component: () => import('@/views/admin/chengguo/edit')
    },
    //工作室编辑
    {
      path: '/admin/gzs/edit',
      name: '工作室',
      hidden: true,
      component: () => import('@/views/admin/gzs/edit')
    },
    //专家编辑
    {
      path: '/expertEdit',
      name: '专家',
      hidden: true,
      component: () => import('@/views/expertEdit')
    },
  ])

  router.addRoute(router.options.routes[0]);
}

export function Permission(arr) {
  // 拿到只是菜单的路由
  const meuns = arr.filter(i => i.is_menu === 1)
  const routes = meuns.map(item => {

    let power = JSON.parse(JSON.stringify(item.power));
    let el = {};

    if(item.title.includes('工作室列表')){
      item.title = '创新工作室'
    }
    
    if(item.title == '专家成果列表' || item.title == '管理员成果列表'){
      item.title = '创新成果'
    }

    if(item.title == '2023管理员创新成果' || item.title == '2023专家成果列表'){
      item.title = '2023创新成果'
    }
    
    el.meta = { name: item.title, id: item.id, queryText: '', pid: item.pid};
    el.name = item.title + '_' + item.id

    if(power.includes('?')){
      power = power.split('?')[0];
      el.meta.queryText = '?' + item.power.split('?')[1]
    }
    
    el.path = `/${power}`;
  
    if(item.child && item.child.length>0){
      let length = 0;

      for(let i=0; i<item.child.length; i++){
        if(item.child[i].is_menu==1){
          length ++
        }
      }

      if(length>0){
        el.children = Permission(item.child)
      }else{
        el.component = () => import(`@/views/${power}.vue`)
      }
    }else{
      el.component = () => import(`@/views/${power}.vue`)
    }
  
    return el
  })
  
  return routes
}

//按钮权限
export function PermissionBtn (arr, data){
  data.forEach(el=>{
      if(el.is_menu==2){
          arr.push(el.power)
      }
      if(el.child&&el.child.length>0&&el.child instanceof Array){
        PermissionBtn(arr, el.child)
      }
  })
  return arr
}

//控制按钮显示隐藏
export function BtnShow(path){
  let arr = JSON.parse(sessionStorage.getItem('BtnRoutes'))
  if(arr.includes(path)){
      return true
  }else{
      return false
  }
}

//生成模块菜单
export function setModuleMenu(){
  const routes = router.options.routes[0].children;
  let moduleRoutes = [];

  if(sessionStorage.getItem('moduleMenuIds')){
    const ids = sessionStorage.getItem('moduleMenuIds').split(',');

    if(ids[0] == 0){
      moduleRoutes.push(
        {
          name: '数据看板',
          path: '/board/index',
          meta: {
            name: '数据看板',
            id: 0,
            queryText: '',
            pid: 0
          }
        }
      );
    }else{
      for(let i=0; i<routes.length; i++){
        if(routes[i].meta && routes[i].meta.id && ids.join().includes(String(routes[i].meta.id))){
          console.log(routes[i])
          moduleRoutes.push(routes[i]);
        }
      }
    }
  }else{
    moduleRoutes = routes;
  }

  if(moduleRoutes.length > 0){
      sessionStorage.setItem('moduleRoutes', JSON.stringify(moduleRoutes));
      return true
  }else{
      return false
  }
}